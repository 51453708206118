import React from "react";
import "./Impressum.css";

const Impressum = () => {
    return(
        <div className="impressum-container">
            <h2>Why not Bowl GmbH</h2>
            <p>Rüttenscheiderstr 105<br/>
                45130 Essen</p>
            <br />
            <p>Geschäftsführer: Basel Madarati</p>
            <p>Telefon: +49 0176 18358175</p>
        </div>
    )}
    export default Impressum