import logo from '../assests/logo.webp';
import header from '../assests/header.webp';
import salad from '../assests/salat.webp';
import sandwish from '../assests/sandiwsh.webp';
import shawarma from '../assests/shawarma.webp';
import hummus from '../assests/hummus.webp';
import besondere from '../assests/besondere.webp';
import taco from '../assests/taco.webp';
import menu from '../assests/menu.webp';
import menu01 from '../assests/Menu01.webp';
import menu02 from '../assests/Menu02.webp';
import menu03 from '../assests/Menu03.webp';
import menu04 from '../assests/Menu04.webp';
import menu05 from '../assests/Menu05.webp';


const images = {
    logo, header,
    salad, sandwish,shawarma, hummus, besondere,
    taco, menu,menu01,menu02,menu03,menu04,menu05
 };

 export default images;
