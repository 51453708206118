import React from "react";
import { Navbar, Footer, AboutUsHeader, Kontakt } from "../../components"
import "./Kontakt.css"

function Contakt(){
    return(
        <div className="kontakt">
            <Navbar />
            <AboutUsHeader />
            <Kontakt />
            <Footer />
        </div>
    )
}
export default Contakt