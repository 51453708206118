import React from "react";
import { Impressum, AboutUsHeader,Footer, Navbar } from "../../components";

const ImpressumPage = () => {
    return(
        <div className="impressum">
            <Navbar />
            <AboutUsHeader />
            <Impressum />
            <Footer />
        </div>
    )}
    export default ImpressumPage;